<template>
  <div class="recongnition-stroke-page-container">
    <div class="red-background-border"></div>

    <div class="recongnition-stroke-page-box">
      <PageButton
        :bottomCornerButtonList="bottomCornerButtonList"
        :isLastStep="isLastStep"
        @clickNext="nextPage"
        @updateNumbers="updateNumbers($event)"
      ></PageButton>
      <div class="recongnition-stroke-page-content layout-red-bgc">
        <div class="recognize_container_content_left">
          <img :src="backgroundImage" alt="" />
        </div>
        <div class="recognize_container_content_right">
          <div class="recognize_container_content_right_border">
            <div class="recognize_container_content_right_content">
              <div class="recognize_container_content_right_content_top">
                <div>
                  <img class="stroke-image" :src="strokeObj.strokeImg" alt="" />
                </div>
              </div>
              <div class="redColor">
                <p class="font-pinyin-medium pinyin">{{ strokeObj.pinName }}</p>
                <p class="font-hanzi-medium">{{ strokeObj.chineseName }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";

export default {
  components: {
    PageButton,
  },
  props: {
    strokeObj: {
      type: Object,
      require: true,
      default: () => {},
    },
    backgroundImage: {
      type: String,
      require: true,
    },
  },
  data() {
    return {
      isHover: false,
      isLastStep: true,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "next",
            startNumberRange: 1,
            endNumberRange: 1,
            clickGoToNextPage: true,
          },
        ],
      },
    };
  },
  methods: {
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.recongnition-stroke-page-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  .recongnition-stroke-page-box {
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    background: #fff;
    border-radius: 58px;
    z-index: 2;
    position: relative;
    .recongnition-stroke-page-content {
      background: #f7f4e0;
      border-radius: 58px;
      position: relative;
      z-index: 3;
      overflow: hidden;
      .recognize_container_content_left {
        width: 50%;
        height: 100%;
        position: absolute;
        img {
          position: absolute;
          right: 0;
          top: 20%;
          width: 80%;
        }
      }
      .recognize_container_content_right {
        position: absolute;
        width: 50%;
        height: 100%;
        right: 0;
        .recognize_container_content_right_border {
          // min-width: 280px;
          min-height: 360px;
          max-width: 400px;
          max-height: 500px;

          margin-top: 10px;
          margin-left: 10px;
          width: calc(65% - 10px);
          height: calc(60% - 10px);
          position: absolute;
          left: 14%;
          top: 13%;
          background: #224e96;
          border-radius: 30px;

          @media screen and (max-width: 1300px) {
            // ipad
            min-height: 300px;
          }

          .recognize_container_content_right_content {
            min-height: 350px;
            max-width: 390px;
            max-height: 490px;

            color: #000;
            text-align: center;
            position: absolute;
            width: 100%;
            height: 100%;
            background: #fff;
            border-radius: 30px;
            top: -10px;
            left: -10px;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            cursor: default;
            @media screen and (max-width: 1300px) {
              // ipad
              min-height: 300px;
            }
            .recognize_container_content_right_content_top {
              width: 70%;

              @media screen and (max-height: 900px) {
                width: 60%;
              }

              .stroke-image {
              }
              div {
                width: 100%;
                height: 0;
                padding-bottom: 100%;
              }
              img {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
  .font25 {
    font-size: 25px;
    line-height: 25px;
  }
  .font60 {
    font-size: 60px;
    line-height: 80px;
  }
  .redColor {
    color: #ce524c;
  }
}
</style>
